import horiLogo from '../../assets/images/horiLogo.png';
import microsoftExcel from '../../assets/images/microsoftExcel.png';
import veriLogo from '../../assets/images/vertiLogo.jpeg';
import enflag from '../../assets/images/flags/en.svg';
import deflag from '../../assets/images/flags/de.svg';
import esflag from '../../assets/images/flags/es.svg';
import hiflag from '../../assets/images/flags/hi.svg';
import AugmentLogo from '../../assets/images/augmentLogo.jpg';
import AugmentLogoSmall from '../../assets/images/augment8LogoSmall.png';
import PaypromtLogo from '../../assets/images/augmentNewLogo.png';
import LoadingGif from '../../assets/gifs/loading.gif';

export default function imageFlag(type) {
  let flag;
  switch (type) {
    case 'de':
      flag = deflag;
      break;
    case 'es':
      flag = esflag;
      break;
    case 'hi':
      flag = hiflag;
      break;
    default:
      flag = enflag;
  }

  return flag;
}

export const Images = {
  horiLogo,
  veriLogo,
  microsoftExcel,
  AugmentLogo,
  AugmentLogoSmall,
  PaypromtLogo,
  LoadingGif,
};
