// Project Imports

import apiEndpoint from 'core/common/apiEndPoints';
import axiosInstance from 'providers/axiosInstance/axiosInstance';
import useLogger from 'core/utils/useLogger';

const fetchNotifications = async (userId): Promise<void> => {
  const logger = useLogger();
  try {
    const response = await axiosInstance.get<any[]>(
      `${process.env.REACT_APP_API_URL}/${apiEndpoint.workFlowStatus}/`
    );
    const responseData = (response.data as any)?.results;
    const userSpecificNotifications = responseData.filter(
      (item) => item?.created_by === userId
    );
    logger('workflowStatusandUserDetails', {
      userSpecificNotifications,
      userId,
    });
  } catch (error) {
    logger('Error while fetching permissions', error);
  }
};

export default fetchNotifications;
