// Project Imports

import apiEndpoint from 'core/common/apiEndPoints';
import resources from 'core/resources/resources';
import { type RolePermission } from 'interfaces/interface';
import type ResourceItem from 'interfaces/resource';
import axiosInstance from 'providers/axiosInstance/axiosInstance';

import DashboardResource from 'core/resources/Dashboard';

import fetchNotifications from 'core/utils/fetchNotifications';

const fetchPermissions = async (
  setFilteredResources: React.Dispatch<React.SetStateAction<ResourceItem[]>>,
  setCrudPermission: React.Dispatch<React.SetStateAction<string[]>>,
  setModulePermission: React.Dispatch<React.SetStateAction<string[]>>,
  setUserDetails: React.Dispatch<React.SetStateAction<any[]>>,
  changeLanguage: React.Dispatch<React.SetStateAction<any>>,
  setCurrentLang: React.Dispatch<React.SetStateAction<any>>,
  connect: any,
  currentLang: any,
  token: any,
  setOrgId: React.Dispatch<React.SetStateAction<string>>,
  setRoleId: React.Dispatch<React.SetStateAction<string>>
): Promise<void> => {
  try {
    const response = await axiosInstance.get<RolePermission[]>(
      `${process.env.REACT_APP_USER_API_URL}/${apiEndpoint.userEvalPermissionDetails}/`
    );
    const responseData = (response.data as any)?.results;
    const crudPermission = responseData?.action_master_list;
    const userDetails = responseData?.user_details;

    const allOtherPermission =
      responseData.screen_master_list +
      responseData.module_master_list +
      responseData.sub_module_master_list +
      responseData.action_master_list;
    setModulePermission(allOtherPermission);
    setCrudPermission([...crudPermission, 'dashboard', 'profile']);

    const newResources = resources.filter((resource: any) =>
      allOtherPermission.includes(resource.identifier)
    );
    setFilteredResources([DashboardResource, ...newResources]);
    setUserDetails(userDetails);
    localStorage.setItem('userEmailId', userDetails?.user_mail_id || '');

    // const hDetails = await h.getSessionDetails();
    // const hSecureId = hDetails?.sessionSecureID;
    // const randomId = Math.random().toString(16).slice(2);
    // const xHighlightRequest = `${hSecureId}/${randomId}`;

    if (userDetails?.selected_language) {
      changeLanguage(userDetails?.selected_language);
      setCurrentLang(userDetails?.selected_language);
    }

    if (userDetails?.unique_id) {
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
        'organization-id': userDetails?.organization_id,
        'Accept-Language': currentLang,
        'role-id': userDetails?.role_id,
        // 'x-highlight-request': xHighlightRequest,
      };
      setOrgId(userDetails?.organization_id);
      setRoleId(userDetails?.role_id);
      connect(userDetails?.unique_id, userDetails?.organization_id);
      await fetchNotifications(userDetails?.unique_id);
    }
  } catch (error) {
    const moduleWithAccess = ['dashboard'];
    const newResources = resources.filter((resource: any) =>
      moduleWithAccess.includes(resource.identifier)
    );
    setFilteredResources(newResources);
  }
};

export default fetchPermissions;
