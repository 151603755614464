// Project Imports
import useLogger from 'core/utils/useLogger';

const authError = async (error: Error) => {
  const logger = useLogger();
  logger('', error);
  return { error };
};

export default authError;
